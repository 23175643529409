import React, { Component } from 'react';

import { Menu, MenuItem } from '@progress/kendo-react-layout';
import { withRouter } from 'react-router-dom';
// Enable below code for christmas decorations.
//import logo from '../static/images/spark_logo_modified_xmas.png';
// Comment below code for christmas decoration
import logo from '../static/images/spark_logo_modified.png';
import logoOnly from '../static/images/spark_logo_only.png';
import { PermissibleRender } from '@brainhubeu/react-permissible';
import {
  checkCanApproveTimeCard
} from './NavigationService';

const version = require('../../config').get(process.env.REACT_APP_ENV).version;

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapse: false,
      canApprove: false,
      IsUserActive: false,
      employmentType: null
    };
  }

  componentDidMount() {
    checkCanApproveTimeCard(window.LOGGED_USER.user).then(res => {
      this.setState({
        canApprove: res.data.canApprove,
        IsUserActive: res.data.IsUserActive,
        employmentType: res.data.EmploymentType
      });
    });
  }

  toggleCollapse = () => {
    this.setState({
      isCollapse: !this.state.isCollapse
    });
    this.props.onToggleCollapse();
  };

  render() {
    return (
      <div>
        <div className="mb-sidenav">
          {/* <div className="mb-menu">
            <div className="mb-close-btn">
              <span className="k-icon k-i-close"></span>
            </div>
            <Accordion>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Home
                    </AccordionItemButton>
                </AccordionItemHeading>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Resources
                    </AccordionItemButton>
                </AccordionItemHeading>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Projects
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <MenuItem
                    text='Create'
                    data={{ route: '/projects/create/create' }}
                  />

                  <MenuItem
                    text='Search'
                    data={{ route: '/projects/search/search' }}
                  />
                </AccordionItemPanel>
              </AccordionItem>

            </Accordion>
          </div> */}

          <div className="mb-logo">
            <img src={logo} alt="RMS Logo" />
          </div>
        </div>

        <div className={'sidenav-wrapper ' + (this.state.isCollapse ? 'sidenav-collapse' : '')}>
          <div
            id="arrowIcon"
            className={
              'arrow k-icon ' +
              (this.state.isCollapse ? 'k-i-arrow-chevron-right' : 'k-i-arrow-chevron-left')
            }
            onClick={this.toggleCollapse}
          />
          <div className="sidenav">
            <div className="logo">
              <img src={logo} alt="RMS Logo" />
            </div>
            <div className="logoOnly" style={{ textAlign: 'end' }}>
              <img src={logoOnly} alt="RMS Logo Only" style={{ width: '70px' }} />
            </div>

            <div className="main-menu">
              <Menu
                className="active"
                vertical
                style={{ display: 'inline-block' }}
                onSelect={this.onSelect}
              >
                <MenuItem text="Home" cssClass="icon icon-home" data={{ route: '/' }} />
                <MenuItem text="Projects" cssClass="icon icon-projects">
                  {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('CRT_PRJ') > -1 && (
                    <MenuItem text="Create" data={{ route: '/projects/create/create' }} />
                  )}

                  {window.USER_PERMISSION &&
                    (window.USER_PERMISSION.indexOf('RES_MGMNT') > -1 ||
                      window.USER_PERMISSION.indexOf('VW_PRJ') > -1 ||
                      window.USER_PERMISSION.indexOf('FAC_PRO') > -1 ||
                      window.USER_PERMISSION.indexOf('VW_FAC_PRJ') > -1) && (
                      <MenuItem text="Search" data={{ route: '/projects/search/search' }} />
                    )}

                  {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('VW_CMS') > -1 && (
                    <MenuItem
                      text="Consolidated CMS"
                      data={{ route: '/projects/CMSheet/ConsolidatedCMSheet/ConsolidatedCMSheet' }}
                    />
                  )}

                  {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('FAC_PRO') > -1 && (
                    <MenuItem
                      text="Create Factory Project"
                      data={{ route: '/PoolProject/FactoryMain' }}
                    />
                  )}
                </MenuItem>
                <MenuItem text="Project Invoicing" cssClass="icon icon-invoicing">
                  {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('CRT_PRJ') > -1 && (
                    <MenuItem text="Create Invoice Request" data={{ route: '/projects/invoice/create' }} />
                  )}
                  {window.USER_PERMISSION && 
                  (window.USER_PERMISSION.indexOf('CRT_PRJ') > -1 ||
                  window.USER_PERMISSION.indexOf('EDT_COS_CR') > -1) && (
                    <MenuItem text="View All Invoice Requests" data={{ route: '/projects/invoice/invoicehistory' }} />
                  )}
                  {window.USER_PERMISSION && (window.USER_PERMISSION.indexOf('ASIA_SPOC') > -1 ||
                  window.USER_PERMISSION.indexOf('AMERICAS_SPOC') > -1 ||
                  window.USER_PERMISSION.indexOf('ANZ_SPOC') > -1 ||
                  window.USER_PERMISSION.indexOf('UK_EUROPE_SPOC') > -1 ) && (
                    <MenuItem text="Generate IRF" data={{ route: '/projects/invoice/irfview' }} />
                  )}
                  {window.USER_PERMISSION && 
                  (window.USER_PERMISSION.indexOf('CRT_PRJ') > -1 ||
                  window.USER_PERMISSION.indexOf('EDT_COS_CR') > -1) && (
                    <MenuItem text="Pending IR Creation" data={{ route: '/projects/invoice/pendinginvoicerequests' }} />
                  )}
                </MenuItem>
                <MenuItem text="Resources" cssClass="icon icon-resources">
                  {/* <MenuItem
                text="Allocate Pre-sales"
                data={{ route: '/resources/allocatepresales/allocatepresales' }}
                /> */}
                  {window.USER_PERMISSION &&
                    (window.USER_PERMISSION.indexOf('VW_OS_RES') > -1 ||
                      window.USER_PERMISSION.indexOf('EDT_OS_RES') > -1) && (
                      <MenuItem
                        text="Resource Calendar Mapping"
                        data={{
                          route: '/resourceManagement/resourceOnsiteDuration/resourceOnsiteDuration'
                        }}
                      />
                    )}
                </MenuItem>

                <MenuItem text="Timesheets" cssClass="icon icon-timesheets">
                  {window.USER_PERMISSION &&
                    (window.USER_PERMISSION.indexOf('VW_NP_TASK') > -1 ||
                      window.USER_PERMISSION.indexOf('CONFIG_NP_TASK') > -1) && (
                      <MenuItem
                        text="Non-Project Task"
                        data={{ route: '/timesheets/nonprojecttasksearch/nonprojecttasksearch' }}
                      />
                    )}
                  {window.USER_PERMISSION && this.state.IsUserActive === true && (
                    <MenuItem text="Time Entry" data={{ route: '/timesheets/timesheetentry' }} />
                  )}
                  )
                  {window.USER_PERMISSION && this.state.IsUserActive === true && (
                    <MenuItem text="Time Entry Lite" data={{ route: '/timesheets/timesheetentrylite' }} />
                  )}
                  
                   {window.USER_PERMISSION &&
                    (window.USER_PERMISSION.indexOf('VW_UNFREEZE_REQ') > -1) && (
                      <MenuItem
                        text="View Unfreeze Requests"
                        data={{ route: '/timesheets/unfreezeworkflow/unfreezeworkflow' }}
                      />
                      )}
                  {window.USER_PERMISSION &&
                    (this.state.canApprove === true ||
                      window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1) && (
                      <MenuItem
                        text="Timesheet Approval"
                        data={{ route: '/timesheets/timesheetapproval' }}
                      />
                  )}
                  {window.USER_PERMISSION &&
                    (window.USER_PERMISSION.indexOf('VW_ALL_TM_SHT') > -1) && (
                        <MenuItem
                        text="View All Timesheets"
                        data={{ route: '/timesheets/viewalltimesheets' }}
                      />
                  )}
                </MenuItem>

                <MenuItem text="Resource Utilization" cssClass="icon icon-billing">
                  {window.USER_PERMISSION && (this.state.IsUserActive === true && this.state.employmentType && this.state.employmentType !== 1) && (
                      <MenuItem 
                        text="Self Utilization & Billing" 
                        data={{ route: '/timesheets/resourceutilization' }} 
                      />
                  )}
                  <MenuItem 
                    text="Daily Resource Utilization" 
                    data={{ route: '/timesheets/dailyutilization' }} 
                  />
                </MenuItem>

                <MenuItem text="QMS Number Generator" cssClass="icon icon-reports">
                  <MenuItem
                    text="Generate QMS Number"
                    data={{ route: '/qms/generateNumber/generateNumber' }}
                  />
                  {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('VW_QMS') > -1 && (
                    <MenuItem text="QMS History" data={{ route: '/qms/viewqmslist/viewqmslist' }} />
                  )}
                  {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('EDT_QMS') > -1 && (
                    <MenuItem text="QMS Templates" data={{ route: '/qms/search/search' }} />
                  )}
                </MenuItem>

                <MenuItem text="Admin" cssClass="icon icon-admin">
                  {window.USER_PERMISSION &&
                    (window.USER_PERMISSION.indexOf('USER_ROLE_MGMNT') > -1 ||
                      window.USER_PERMISSION.indexOf('EDT_RSC_PROF') > -1 || 
                      window.USER_PERMISSION.indexOf('USR_MGT') > -1) && (
                      <MenuItem
                        text="User Management"
                        data={{ route: '/admin/usermanagement/usermanagement' }}
                      />
                    )}
                  {window.USER_PERMISSION &&
                    (window.USER_PERMISSION.indexOf('USER_ROLE_MGMNT') > -1 || 
                    window.USER_PERMISSION.indexOf('ROLE_MGT') > -1) && (
                      <MenuItem
                        text="Role Management"
                        data={{ route: '/admin/rolemanagement/rolemanagement' }}
                      />
                    )}

                  {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('VW_AT') > -1 && (
                    <MenuItem
                      text="Audit Trail"
                      data={{ route: '/admin/audittrail/audittrailsearch' }}
                    />
                  )}
                </MenuItem>
                <MenuItem text="Workflow" cssClass="icon icon-workflow">
                  {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('WF_CUS') > -1 && (
                    <MenuItem
                      text="Create"
                      data={{ route: '/admin/createworkflow/createworkflow' }}
                    />
                  )}

                  {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('VW_WKF') > -1 && (
                    <MenuItem text="Search" data={{ route: '/admin/workflow/workflow' }} />
                  )}

                  {window.USER_PERMISSION &&
                    (window.USER_PERMISSION.indexOf('VW_WFCRI') > -1 ||
                      window.USER_PERMISSION.indexOf('EDT_WFCRI') > -1) && (
                      <MenuItem
                        text="Workflow Parameters Definition"
                        data={{
                          route:
                            '/WorkflowCriteriaDefinitionSearch/WorkflowCriteriaDefinitionSearch'
                        }}
                      />
                    )}

                  {window.USER_PERMISSION &&
                    (window.USER_PERMISSION.indexOf('VW_APPR_TY') > -1 ||
                      window.USER_PERMISSION.indexOf('EDT_APPR_TY') > -1) && (
                      <MenuItem
                        text="Approver Type"
                        data={{ route: '/admin/approvertype/searchapprovertype' }}
                      />
                    )}
                </MenuItem>
                {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('MST_FLS') > -1 && (
                  <MenuItem text="Master Data" cssClass="icon icon-master-data">
                    {window.USER_PERMISSION &&
                      (window.USER_PERMISSION.indexOf('VW_REG') > -1 ||
                        window.USER_PERMISSION.indexOf('EDT_RGN') > -1) && (
                        <MenuItem text="Region" data={{ route: '/admin/region/region' }} />
                      )}

                    {window.USER_PERMISSION &&
                      (window.USER_PERMISSION.indexOf('VW_CUST') > -1 ||
                        window.USER_PERMISSION.indexOf('EDT_CUST') > -1) && (
                        <MenuItem text="Customer" data={{ route: '/admin/customer/search' }} />
                      )}

                    {window.USER_PERMISSION &&
                      (window.USER_PERMISSION.indexOf('VW_ENG_TYP') > -1 ||
                        window.USER_PERMISSION.indexOf('ED_ENG') > -1) && (
                        <MenuItem
                          text="Engagement Type"
                          data={{ route: '/masterdata/engagementtype/engagementtype' }}
                        />
                      )}

                    {window.USER_PERMISSION &&
                      (window.USER_PERMISSION.indexOf('VW_DOC_TYPE') > -1 ||
                        window.USER_PERMISSION.indexOf('EDT_DOC_TYPE') > -1) && (
                        <MenuItem
                          text="Document Type"
                          data={{ route: '/masterdata/documenttype/documenttypesearch' }}
                        />
                      )}

                    {window.USER_PERMISSION &&
                      (window.USER_PERMISSION.indexOf('VW_PRCT') > -1 ||
                        window.USER_PERMISSION.indexOf('ED_PRA') > -1) && (
                        <MenuItem
                          text="Department"
                          data={{ route: '/masterdata/practice/practice' }}
                        />
                      )}

                    {window.USER_PERMISSION &&
                        (window.USER_PERMISSION.indexOf('VW_PRCT') > -1 ||
                            window.USER_PERMISSION.indexOf('ED_PRA') > -1) && (
                            <MenuItem
                                text="Practice"
                                data={{ route: '/masterdata/practice/practiceSF' }}
                            />
                        )}

                    {window.USER_PERMISSION &&
                      (window.USER_PERMISSION.indexOf('VW_OFF_TYP') > -1 ||
                        window.USER_PERMISSION.indexOf('EDT_OFF') > -1) && (
                        <MenuItem
                          text="Offering"
                          data={{ route: '/admin/offerings/search' }}
                          cssClass="separator-menu"
                        />
                      )}

                    {window.USER_PERMISSION &&
                      (window.USER_PERMISSION.indexOf('VW_CST_CRD') > -1 ||
                        window.USER_PERMISSION.indexOf('EDT_COS_CR') > -1) && (
                        <MenuItem text="Cost Card" data={{ route: '/admin/CostCard/Search' }} />
                      )}

                    {window.USER_PERMISSION &&
                      (window.USER_PERMISSION.indexOf('VW_RT_CRD_MGT') > -1 ||
                        window.USER_PERMISSION.indexOf('EDT_RATE_CRD') > -1) && (
                        <MenuItem
                          text="Rate Card"
                          data={{ route: '/admin/ratecard/ratecard' }}
                          cssClass="separator-menu"
                        />
                      )}

                    {window.USER_PERMISSION &&
                      (window.USER_PERMISSION.indexOf('VW_CLDR') > -1 ||
                        window.USER_PERMISSION.indexOf('ED_CAL') > -1) && (
                        <MenuItem text="Calendar" data={{ route: '/admin/Calendar/Search' }} />
                      )}

                    {window.USER_PERMISSION &&
                      (window.USER_PERMISSION.indexOf('VW_COMM') > -1 ||
                        window.USER_PERMISSION.indexOf('EDT_COMM') > -1) && (
                        <MenuItem
                          text="Commissions"
                          data={{ route: '/admin/commissions/commissions' }}
                        />
                      )}

                    {window.USER_PERMISSION &&
                      (window.USER_PERMISSION.indexOf('VW_EXG_RT') > -1 ||
                        window.USER_PERMISSION.indexOf('EDT_EXG_RT') > -1) && (
                        <MenuItem
                          text="Exchange Rate"
                          data={{ route: '/admin/ExchangeRate/ExchangeRate' }}
                        />
                      )}

                    {window.USER_PERMISSION &&
                      (window.USER_PERMISSION.indexOf('VW_MUL_YR_INC') > -1 ||
                        window.USER_PERMISSION.indexOf('EDT_MUL_YR_INC') > -1) && (
                        <MenuItem
                          text="Multiyear Increment"
                          data={{ route: '/admin/multiyearincrement/multiyearincrementinfo' }}
                          cssClass="separator-menu"
                        />
                      )}

                    {window.USER_PERMISSION &&
                      (window.USER_PERMISSION.indexOf('VW_DSG') > -1 ||
                        window.USER_PERMISSION.indexOf('ED_DEG') > -1) && (
                        <MenuItem
                          text="Cost Designation"
                          data={{ route: '/admin/designation/designation' }}
                        />
                      )}
                    {window.USER_PERMISSION &&
                      (window.USER_PERMISSION.indexOf('VW_DSG_GRP') > -1 ||
                        window.USER_PERMISSION.indexOf('ED_DEG_GP') > -1) && (
                        <MenuItem
                          text="Designation Group"
                          data={{ route: '/admin/designationgroup/designationgroup' }}
                        />
                      )}
                    {window.USER_PERMISSION &&
                      (window.USER_PERMISSION.indexOf('VW_HR_DESG') > -1 ||
                        window.USER_PERMISSION.indexOf('EDT_HR_DESG') > -1) && (
                        <MenuItem
                          text="HR Designation"
                          data={{ route: '/admin/hrdesignation/search' }}
                        />
                      )}
                    {window.USER_PERMISSION &&
                      (window.USER_PERMISSION.indexOf('VW_TSKTYP') > -1 ||
                        window.USER_PERMISSION.indexOf('EDT_TSKTYP') > -1) && (
                        <MenuItem
                          text="Task Type"
                          data={{ route: '/masterdata/tasktype/tasktype' }}
                        />
                      )}
                  </MenuItem>
                )}
                <MenuItem text="Reports" cssClass="icon icon-reports" />
                <MenuItem text="Help" cssClass="icon icon-help" data={{ route: '/Help/Help' }} />
              </Menu>
            </div>

            <div className="verison-number">{version}</div>
          </div>
        </div>
      </div>
    );
  }

  onSelect = event => {
    if (event && event.item && event.item.data) {
      this.props.history.push(event.item.data.route);
    }
  };
}

export default withRouter(Navigation);
