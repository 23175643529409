const config = {
  common:{
    jwtRefreshInterval: 15 //Minutes
  },
  development: {
    apiUrl: 'http://localhost:5000/api',
    serverUrl: 'http://localhost:5000',
    analyticsCode: 'UA-142472168-1',
    version: '24.12.1',
    clientId: '797c0070-4686-49b4-a12c-a65179b6a666',
    authority: 'https://login.microsoftonline.com/ea1f9c15-7f32-4dfa-b662-cacea1f61d0f',
    scopes: ['openid','email', 'https://brandix.onmicrosoft.com/33689e39-77cf-40a2-a187-9e1ebbbffd7c/user_impersonation']
  },
  devQA: {
    apiUrl: 'https://ftd-rp-db-pgsql.southeastasia.cloudapp.azure.com:50500/api',
    serverUrl: 'https://ftd-rp-db-pgsql.southeastasia.cloudapp.azure.com:50500',
    analyticsCode: 'UA-142472168-1',
    version: '24.12.1',
    clientId: '1b4ff062-3e9a-472b-bf23-31bea69d4bb7',
    authority: 'https://login.microsoftonline.com/ea1f9c15-7f32-4dfa-b662-cacea1f61d0f',
    scopes: ['openid', 'email', 'https://brandix.onmicrosoft.com/1b4ff062-3e9a-472b-bf23-31bea69d4bb7/user_impersonation']
  },
  test: {
    apiUrl: 'https://ftd-rp-db-pgsql.southeastasia.cloudapp.azure.com/api',
    serverUrl: 'https://ftd-rp-db-pgsql.southeastasia.cloudapp.azure.com',
    analyticsCode: 'UA-142472168-1',
    version: '24.12.1',
    clientId: '99e5be4e-dc89-422c-9263-c4d90ad759eb',
    authority: 'https://login.microsoftonline.com/ea1f9c15-7f32-4dfa-b662-cacea1f61d0f',
    scopes: ['openid','email', 'https://brandix.onmicrosoft.com/17c78542-5a6f-43a8-8858-3146ce6d921c/user_impersonation']
  },
  production: {
    apiUrl: 'https://spark.fortude.co/api',
    serverUrl: 'https://spark.fortude.co',
    analyticsCode: 'UA-142474197-1',
    version: '24.12.1',
    clientId: '185d1bff-ca9f-4a8a-b20f-ce9bafe84623',
    authority: 'https://login.microsoftonline.com/ea1f9c15-7f32-4dfa-b662-cacea1f61d0f',
    scopes: ['openid','email', 'https://brandix.onmicrosoft.com/38feaa75-5334-4bee-9412-99e922f2299d/user_impersonation']
  },
  uat: {
    apiUrl: 'https://ftd-rms-uat-app.southeastasia.cloudapp.azure.com/api',
    serverUrl: 'https://ftd-rms-uat-app.southeastasia.cloudapp.azure.com',
    analyticsCode: 'UA-142472168-1',
    version: '24.12.1',
    clientId: 'cca0222b-c834-4a9e-807f-902869756de5',
    authority: 'https://login.microsoftonline.com/ea1f9c15-7f32-4dfa-b662-cacea1f61d0f',
    scopes: ['openid','email', 'https://brandix.onmicrosoft.com/fa4f3d1d-d638-4b11-8ce2-a4d52c60b09e/user_impersonation']
  },
  regression: {
    apiUrl: 'https://ftd-rp-db-pgsql.southeastasia.cloudapp.azure.com/api',
    serverUrl: 'https://ftd-rp-db-pgsql.southeastasia.cloudapp.azure.com',
    analyticsCode: 'UA-142472168-1',
    version: '24.12.1',
    clientId: '99e5be4e-dc89-422c-9263-c4d90ad759eb',
    authority: 'https://login.microsoftonline.com/ea1f9c15-7f32-4dfa-b662-cacea1f61d0f',
    scopes: ['openid','email', 'https://brandix.onmicrosoft.com/17c78542-5a6f-43a8-8858-3146ce6d921c/user_impersonation']
  }
};

exports.get = function get(env) {
  let conf = config[env] || config.development;
  Object.assign(conf, config.common);
  return conf;
};
