import React, { Component } from 'react';
import { Grid, GridColumn} from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import moment from 'moment';
import 'moment-timezone';
import * as loggerService from "../../integration/LoggerService";
import {LOG_TYPES} from "../../integration/IntegrationEnums";
import { getProjectResources } from '../../resourceManagement/ResourceService';
import { getBillingDiviDropDownIntegrated, getPracticeTypesIntegrated, getskillAreaIntegrated } from '../../integration/MasterDataService';
import { getRegions} from '../../masterData/region/RegionService';
import { getAllDesignations } from "../../masterData/designation/DesignationService";
import {  getcustomers } from "../../masterData/customer/CustomerService";
import { getResourceList, getAllocationList, getAllProjectsAndCrs } from './ResourceUtilizationService';
import CustomCalendar from './CustomCalendar';
import UserRoleEnums from "./components/UserRoleEnums";
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import Loader from '../../integration/components/Loader';

class DailyUtilization extends Component {

  isMount = false;

  constructor(props) {
    super(props);

    this.state = {
        loading: false,
        resources: [],
        resourcesAll: [],
        projectsAndCrs: [],
        projectsAndCrsAll: [],
        departmentData: [],
        departmentDataAll: [],
        practiceData: [],
        practiceDataAll: [],
        designations: [],
        designationsAll: [],
        projectRegions: [],
        projectRegionsAll: [],
        customers: [],
        customersAll: [],
        skillAreas: [],
        skillAreasAll: [],
        selectedResource: null,
        selectedProjectOrCr: null,
        selectedDepartment: null,
        selectedPractice: null,
        selectedDesignation: null,
        selectedProjectRegion: null,
        selectedCustomer: null,
        selectedSkillArea:null,
        startDate: new Date(),
        resourceList: [],
        allocationList: [],
        dailyTotalHours: [],
        dateColumnsArray: [],
        expandedPanels: new Set(),
        pmUserRole: false,
        isDisable: false,
    };
  }

  async componentDidMount() {
    this.isMount = true;
    this.props.onHeaderTitleChange('Daily Resource Utilization');
    await this.populateInitialData();
    await this.checkUserRole();
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  populateInitialData = async () => {
    await this.setState({
        loading: true
    });
    await this.getAllActiveResources();
    await this.populateProjectsAndCRs();
    await this.populateDepartments();
    await this.populatePractices();
    await this.populateDesignations();
    await this.populateProjectRegions();
    await this.populateCustomers();
    await this.populateSkillAreas();
    await this.setState({
        loading: false
    });
  }

  checkUserRole = async () => {
    const userRoles = window.LOGGED_USER.roles;
    const pmUserRole = userRoles.some(obj => 
      obj.Rolename === UserRoleEnums.PM || 
      obj.Rolename === UserRoleEnums.PMO || 
      obj.Rolename === UserRoleEnums.FINANCE_MANAGER || 
      obj.Rolename === UserRoleEnums.RP || 
      obj.Rolename === UserRoleEnums.SYSTEM_ADMIN || 
      obj.Rolename === UserRoleEnums.DEPARTMENT_HEAD || 
      obj.Rolename === UserRoleEnums.PRACTICE_HEAD
    );
    await this.setState({
      pmUserRole: pmUserRole
    });

    if (!pmUserRole) {
      const resource = this.state.resourcesAll.filter(val => val.Email.toUpperCase() == window.LOGGED_USER.userId.toUpperCase());
      await this.setState({
        isDisable: true,
        selectedResource: resource
      });
      this.submitSearch();
    }
  }

  getAllActiveResources = async () => {
    await getProjectResources() 
      .then(async res => {
        await this.setState({
          resources: res.data,
          resourcesAll: res.data,
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateProjectsAndCRs = async() => {
    await getAllProjectsAndCrs()
      .then(res => {
        if (this.isMount) {
          this.setState({
            projectsAndCrs: res.data,
            projectsAndCrsAll: res.data,
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateDepartments = async () => {
    await getBillingDiviDropDownIntegrated()
        .then(response => {
            this.setState({
                departmentData: response.data.filter(el => el.IsActive === true && el.SFCode !== null),
                departmentDataAll: response.data.filter(el => el.IsActive === true && el.SFCode !== null)
            });
        })
        .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
  };

  populatePractices = async() => {
    await getPracticeTypesIntegrated()
      .then(res => {
        if (this.isMount) {
          this.setState({
            practiceData: res.data,
            practiceDataAll: res.data,
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateDesignations = async () => {
    await getAllDesignations()
      .then(res => {
        if (this.isMount) {
          this.setState({
            designations: res.data,
            designationsAll: res.data,
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateProjectRegions = async () => {
    await getRegions()
      .then(res => {
        if (this.isMount) {
          this.setState({
            projectRegions: res.data,
            projectRegionsAll: res.data,
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateCustomers = async () => {
    await getcustomers()
      .then(res => {
        if (this.isMount) {
          this.setState({
            customers: res.data,
            customersAll: res.data,
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateSkillAreas = async () => {
    await getskillAreaIntegrated()
      .then(res => {
        const activeSkillAreas = res.data.filter(val => val.IsActive);
        if (this.isMount) {
          this.setState({
            skillAreas: activeSkillAreas,
            skillAreasAll: activeSkillAreas,
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };


  submitSearch = async event => {
    if (event) {
      event.preventDefault();
    }

    if (this.isMount) {
      await this.setState({
        loading: true
      });
    }

    if (this.state.startDate) {

      const firstDateOfMonth = new Date(this.state.startDate).setDate(1);
      const value = moment(new Date(this.state.startDate));
      const lastDateOfMonth = value.endOf('month'); 

      let dateColumnsArray = [];
      let date = new Date(firstDateOfMonth);
      while (date < new Date(lastDateOfMonth)) {
        let formattedDate = moment(date).format('YYYY-MM-DD');
        dateColumnsArray.push(formattedDate);
        date = new Date(moment(date).add(1, 'days'));
      }
  
      const fields = {
          selectedResource: this.state.selectedResource && this.state.selectedResource.length > 0 ? this.state.selectedResource.map(value => value.id) : [],
          selectedProjectOrCr: this.state.selectedProjectOrCr && this.state.selectedProjectOrCr.length > 0 ? this.state.selectedProjectOrCr : [],
          selectedDepartment: this.state.selectedDepartment && this.state.selectedDepartment.length > 0 ? this.state.selectedDepartment.map(value => value.id) : [],
          selectedPractice: this.state.selectedPractice && this.state.selectedPractice.length > 0 ? this.state.selectedPractice.map(value => value.id) : [],
          selectedDesignation: this.state.selectedDesignation && this.state.selectedDesignation.length > 0 ? this.state.selectedDesignation.map(value => value.id) : [],
          selectedProjectRegion: this.state.selectedProjectRegion && this.state.selectedProjectRegion.length > 0 ? this.state.selectedProjectRegion.map(value => value.id) : [],
          selectedCustomer: this.state.selectedCustomer && this.state.selectedCustomer.length > 0 ? this.state.selectedCustomer.map(value => value.id) : [],
          selectedSkillArea: this.state.selectedSkillArea && this.state.selectedSkillArea.length > 0 ? this.state.selectedSkillArea.map(value => value.id) : [],
          StartDate: moment(new Date(firstDateOfMonth)).format('YYYY-MM-DD'),
          EndDate: moment(new Date(lastDateOfMonth)).format('YYYY-MM-DD')
      };        
  
      await getResourceList(fields)
        .then(async res => {
          if (this.isMount) {
              await this.setState({
                  resourceList: res.data,
                  dateColumnsArray: dateColumnsArray            
              });
          }
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });

      await this.expandedPanelQuery();
    }
  
    await this.setState({
      loading: false
    });
  };


  getAllocationList = async (userId) => {

    if (userId && this.state.startDate) {

      await this.setState({
        loading: true,
        expandedPanels: this.state.expandedPanels.add(userId)     
      });

      const firstDateOfMonth = new Date(this.state.startDate).setDate(1);
      const value = moment(new Date(this.state.startDate));
      const lastDateOfMonth = value.endOf('month'); 

      const fields = {
        selectedResource: [userId],
        selectedProjectRegion: this.state.selectedProjectRegion && this.state.selectedProjectRegion.length > 0 ? this.state.selectedProjectRegion.map(value => value.id) : [],
        selectedCustomer: this.state.selectedCustomer && this.state.selectedCustomer.length > 0 ? this.state.selectedCustomer.map(value => value.id) : [],
        selectedProjectOrCr: this.state.selectedProjectOrCr && this.state.selectedProjectOrCr.length > 0 ? this.state.selectedProjectOrCr : [],
        StartDate: moment(new Date(firstDateOfMonth)).format('YYYY-MM-DD'),
        EndDate: moment(new Date(lastDateOfMonth)).format('YYYY-MM-DD')
    };        

    await getAllocationList(fields)
      .then(async res => {
        if (this.isMount) {
            const allocationList = res.data.AllocationList;
            const dailyTotalHours = res.data.DailyTotalHours;
            await this.setState({
              allocationList: {
                ...this.state.allocationList,
                [userId]: allocationList
              },
              dailyTotalHours: {
                ...this.state.dailyTotalHours,
                [userId]: dailyTotalHours
              },
            });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    }

    await this.setState({
      loading: false
    });
  }

  cancelSearch = async event => {
    await this.setState({
        selectedResource: null,
        selectedProjectOrCr: null,
        selectedDepartment: null,
        selectedPractice: null,
        selectedDesignation: null,
        selectedProjectRegion: null,
        selectedCustomer: null,
        selectedSkillArea: null,
        startDate: null,
        expandedPanels: new Set(),
    });
  }

  expandedPanelQuery = async () => {
    try {
      const expaned = Array.from(this.state.expandedPanels);
      if(expaned.length > 0){
        const expandedTasks = await expaned.map(async user => {
          await this.getAllocationList(user);
        });
        await Promise.all(expandedTasks);
        return Promise.resolve();
      }
    } catch (error) {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
      return Promise.reject();
    }
  }

  handleStartDateChange = event => {
    let startDate = event.target.value;
    if (startDate) {
        const firstDateOfMonth = new Date(startDate).setDate(1);
        if (this.isMount) {
          this.setState({
            startDate: new Date(firstDateOfMonth),
          });
        }
    }
  };

  handleInputChange = event => {
    const valueObj = event.target.value;
    const field = event.target.name;
    if (this.isMount) {
      this.setState({
        [field]: valueObj
      });
    }
  };

  filterData(filter, allData) {
    const data = allData.slice();
    return filterBy(data, filter);
  }

  filterResources = event => {
    if (this.isMount) {
      this.setState({
        resources: this.filterData(event.filter, this.state.resourcesAll)
      });
    }
  };

  filterProjectsAndCrs = event => {
    if (this.isMount) {
      this.setState({
        projectsAndCrs: this.filterData(event.filter, this.state.projectsAndCrsAll)
      });
    }
  };

  filterDepartments = event => {
    if (this.isMount) {
      this.setState({
        departmentData: this.filterData(event.filter, this.state.departmentDataAll)
      });
    }
  };

  filterPractices = event => {
    if (this.isMount) {
      this.setState({
        practiceData: this.filterData(event.filter, this.state.practiceDataAll)
      });
    }
  };

  filterDesignations = event => {
    if (this.isMount) {
      this.setState({
        designations: this.filterData(event.filter, this.state.designationsAll)
      });
    }
  };

  filterProjectRegions = event => {
    if (this.isMount) {
      this.setState({
        projectRegions: this.filterData(event.filter, this.state.projectRegionsAll)
      });
    }
  };

  filterCustomers = event => {
    if (this.isMount) {
      this.setState({
        customers: this.filterData(event.filter, this.state.customersAll)
      });
    }
  };

  filterSkillArea = event => {
    if (this.isMount) {
      this.setState({
        skillAreas: this.filterData(event.filter, this.state.skillAreasAll)
      });
    }
  };

  setTimeFormat = time => {
    if (time) {
      let durationParts = time.toString().split('.');
      let duration = durationParts[0];
      let durMinPart = this.roundUp(durationParts[1],1)
      .toString()
      .padStart(2, '0');

      if (typeof durationParts[1] !== 'undefined') {
        if(parseInt(durMinPart) === 60){
          duration = parseInt(duration) + 1;
          durMinPart = '00';
        }else{
          durMinPart = this.roundUp(durationParts[1],1).toString().padStart(2, '0');;
        }
        duration =
          parseInt(duration) +
          ':' + durMinPart
        return duration;
      } else {
        duration = parseInt(duration) + ':' + '00';
        return duration;
      }
    }
  };

  roundUp = (num, precision) => {
    num = parseFloat(`0.${num}`) * 60;
    num = num.toFixed(2) / 10;
    precision = Math.pow(10, precision);
    return (Math.ceil(num * precision) / precision) * 10;
  };

  setDailyTotal = (userId, date, field) => {
    if (this.state.dailyTotalHours) {
      const userTotals = this.state.dailyTotalHours[userId];

      if(!userTotals || !date || !field){
        return <td style={{ textAlign: 'center' }}>{0 + '%'}</td>;
      }
      const dateVal = userTotals[date];
  
      if (dateVal) {
        const uniqueId = field+date+userId;
        return (
          <td style={{ textAlign: 'center' }}>
            <td id={uniqueId} style={{ textAlign: 'center' }}>
              {(field == 'Actual' ? Number(dateVal.ActualPercentage).toFixed(2) : Number(dateVal.PlannedPercentage).toFixed(2)) + '%'}
            </td>
            <UncontrolledTooltip
              modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
              target={uniqueId}
              placement="top"
              style={{ maxWidth: '300px' }}
            >
              <div className="text-left p-1">
                <span>{(field == 'Actual' ? this.setTimeFormat(Number(dateVal.ActualHours).toFixed(2)) : this.setTimeFormat(Number(dateVal.PlannedHours).toFixed(2))) + ' HH:MM'}</span>
              </div>
            </UncontrolledTooltip>
          </td>
        );
      }
    }
    return <td style={{ textAlign: 'center' }}>{0 + '%'}</td>;
  }

  customCell = (props, date, field) => {
    if (props.dataItem.Dates && props.dataItem.Dates.length > 0) {
      const dateVal = props.dataItem.Dates.find(value => value.Date == date);
      if (dateVal) {

        const cellColorStyle = {
          textAlign: 'center',
          backgroundColor: dateVal.isWeekend ? '#bab7b6' :
            dateVal.isLeave || dateVal.isHoliday ? '#b1e0fc' :
            dateVal.isNoAllocation ? '#f7f4c3' : ''
        };

        if (field == 'Actual') {
          cellColorStyle.borderLeft = '1px solid rgba(0, 0, 0, 1)'
        } 

        const checkDay = (dateVal.isWeekend || dateVal.isLeave || 
          dateVal.isHoliday || dateVal.isNoAllocation) ? true : false;
  
        const uniqueId = field+date+props.dataItem.UniqueId;
        return (
          <td style={cellColorStyle}>
            <td id={uniqueId}>
              {!checkDay ? (field == 'Actual' ? dateVal.ActualPercentage : dateVal.PlannedPercentage) + '%' : ''}
            </td>
            {!checkDay && (
              <UncontrolledTooltip
                modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
                target={uniqueId}
                placement="bottom"
                style={{ maxWidth: '300px' }}
              >
                <div className="text-left p-1">
                  <span>{(field == 'Actual' ? this.setTimeFormat(Number(dateVal.ActualHours).toFixed(2)) : this.setTimeFormat(Number(dateVal.PlannedHours).toFixed(2))) + ' HH:MM' + (dateVal.isHalfDay ? ' - Half Day' : '')}</span>
                </div>
              </UncontrolledTooltip>
            )}
          </td>
        );
      } else {
        return <td style={{ textAlign: 'center' }}>{0 + '%'}</td>;
      }
    } else {
      return <td style={{ textAlign: 'center' }}>{0 + '%'}</td>;
    }
  }

  render() {
    return (
      <div className="main-card">
        <div className="row">
          <div className="col-md-8">
            <div className="main-heading">Daily Resource Utilization</div>
          </div>
        </div>

        <form onSubmit={this.submitSearch}>
          <div className="row">

            <div className="col-md-3">
                <label className="">Resource:</label>
                <MultiSelect  
                    data={this.state.resources}
                    name="selectedResource"
                    textField="Name"
                    placeholder={'Please Select'}
                    onChange={this.handleInputChange}
                    value={this.state.selectedResource}
                    filterable={true}
                    onFilterChange={this.filterResources}
                    disabled={this.state.isDisable}
                />
            </div>

            {this.state.pmUserRole && (
              <div className="col-md-3">
                  <label className="">Project/CR Name:</label>
                  <MultiSelect  
                      data={this.state.projectsAndCrs}
                      name="selectedProjectOrCr"
                      textField="DisplayName"
                      placeholder={'Please Select'}
                      onChange={this.handleInputChange}
                      value={this.state.selectedProjectOrCr}
                      filterable={true}
                      onFilterChange={this.filterProjectsAndCrs}
                  />
              </div>
            )}

            {this.state.pmUserRole && (
              <div className="col-md-3">
                  <label className="">Department:</label>
                  <MultiSelect  
                      data={this.state.departmentData}
                      name="selectedDepartment"
                      textField="Name"
                      placeholder={'Please Select'}
                      onChange={this.handleInputChange}
                      value={this.state.selectedDepartment}
                      filterable={true}
                      onFilterChange={this.filterDepartments}
                  />
              </div>
            )}

            {this.state.pmUserRole && (
              <div className="col-md-3">
                  <label className="">Practice:</label>
                  <MultiSelect  
                      data={this.state.practiceData}
                      name="selectedPractice"
                      textField="Name"
                      placeholder={'Please Select'}
                      onChange={this.handleInputChange}
                      value={this.state.selectedPractice}
                      filterable={true}
                      onFilterChange={this.filterPractices}
                  />
              </div>
            )}
          </div>
          

          <div className="row">

            {this.state.pmUserRole && (
              <div className="col-md-3">
                  <label className="">Project Region:</label>
                  <MultiSelect  
                      data={this.state.projectRegions}
                      name="selectedProjectRegion"
                      textField="Name"
                      placeholder={'Please Select'}
                      onChange={this.handleInputChange}
                      value={this.state.selectedProjectRegion}
                      filterable={true}
                      onFilterChange={this.filterProjectRegions}
                  />
              </div>
            )}

            {this.state.pmUserRole && (
              <div className="col-md-3">
                  <label className="">Customer:</label>
                  <MultiSelect  
                      data={this.state.customers}
                      name="selectedCustomer"
                      textField="Name"
                      placeholder={'Please Select'}
                      onChange={this.handleInputChange}
                      value={this.state.selectedCustomer}
                      filterable={true}
                      onFilterChange={this.filterCustomers}
                  />
              </div>
            )}

            {this.state.pmUserRole && (
              <div className="col-md-3">
                  <label className="">Designation:</label>
                  <MultiSelect  
                      data={this.state.designations}
                      name="selectedDesignation"
                      textField="Name"
                      placeholder={'Please Select'}
                      onChange={this.handleInputChange}
                      value={this.state.selectedDesignation}
                      filterable={true}
                      onFilterChange={this.filterDesignations}
                  />
              </div>
            )}

            {this.state.pmUserRole && (
              <div className="col-md-3">
                  <label className="">Skill Area:</label>
                  <MultiSelect  
                      data={this.state.skillAreas}
                      name="selectedSkillArea"
                      textField="name"
                      placeholder={'Please Select'}
                      onChange={this.handleInputChange}
                      value={this.state.selectedSkillArea}
                      filterable={true}
                      onFilterChange={this.filterSkillArea}
                  />
              </div>
            )}

            <div className="col-md-3">
                <div className="d-block">
                  <label htmlFor="">Month: </label>
                </div>
                <div className="d-block">
                    <div className="title-remove">
                        <DatePicker
                            format={'MM/yyyy'}
                            calendar={CustomCalendar}
                            width="100"
                            name="startDate"
                            onChange={this.handleStartDateChange}
                            value={this.state.startDate}
                            formatPlaceholder={{ year: 'YYYY', month: 'MM'}}
                        />
                    </div>
              </div>
            </div>
          </div>

          <div className="row"></div>

          <div className="row">
            <div className="col-md-12 btn-align-right">
              <Button primary={true} type="submit" onClick={this.submitSearch}>
                Search
              </Button>
              <Button type="button" onClick={this.cancelSearch}>
                Clear
              </Button>
            </div>
          </div>

        </form>

        <div className="main-seperator" />

        <div className="row">
          <div className="col-md-6">
            <div className="main-heading">Search Results</div>
          </div>
        </div>

        {/* Color Legends */}
        <div className="row">
          <div className="col-md-12">
            <div className="daily-uitilization-legend-container">
              <div className="daily-uitilization-legend-item">
                <div className="daily-uitilization-legend-box" style={{ backgroundColor: '#bab7b6' }}></div>
                <label>Weekend</label>
              </div>
              <div className="daily-uitilization-legend-item">
                <div className="daily-uitilization-legend-box" style={{ backgroundColor: '#b1e0fc' }}></div>
                <label>Holiday/Leave</label>
              </div>
              <div className="daily-uitilization-legend-item">
                <div className="daily-uitilization-legend-box" style={{ backgroundColor: '#f7f4c3' }}></div>
                <label>No Project Allocation</label>
              </div>
            </div>
          </div>
        </div>

        <div className="row window-ta fade-in">
            <div className="col-md-12 panel-dark">
                {this.state.resourceList && this.state.resourceList.length === 0 &&
                    <div className="no-record-wrap"> No records available </div>
                }
                {this.state.resourceList && this.state.resourceList.length !== 0 &&
                    this.state.resourceList.map(resource => (

                    <PanelBar
                      className="mt-2"
                      key={resource.id}
                      onSelect={e => {
                        this.getAllocationList(resource.id);
                      }}
                    >
                      <PanelBarItem
                        expanded={false}
                        animation={true}
                        title={
                          <div className="font-weight-bold">
                              <label htmlFor="reso-name" className="reso-name">
                                  Resource : {resource.Name}{' '}
                              </label>
                          </div>
                        }
                      >
                      <div className="py-3 daily-uitilization-grid">
                          <Grid
                            data={this.state.allocationList[resource.id]}
                            style={this.state.allocationList[resource.id] && this.state.allocationList[resource.id].length > 10 ? { height: '450px' } : ''}
                          >
                            <GridColumn 
                              field="ProjectName" 
                              width="140px" 
                              title="Project" 
                              locked={true} 
                              footerCell={() => {
                                return <td style={{ position: 'sticky', left: 0, backgroundColor: '#fff', zIndex: 1 }}>{"Total"}</td>;
                              }}
                            />
                            <GridColumn 
                              field="AllocationType" 
                              width="100px" 
                              title="Allocation Type"
                              locked={true} 
                              footerCell={() => {
                                return <td style={{ position: 'sticky', left: '130px', backgroundColor: '#fff', zIndex: 1 }}>{}</td>;
                              }}
                            />  

                            {this.state.dateColumnsArray.map(date => (
                                <GridColumn title={`${date}`}>
                                    <GridColumn
                                        field={''}
                                        title={'Actual'}
                                        width="60px"
                                        cell={props => this.customCell(props, date, 'Actual')}
                                        footerCell={() => this.setDailyTotal(resource.id, date, 'Actual')}
                                    />
                                    <GridColumn
                                        field={''}
                                        title={'Planned'}
                                        width="60px"
                                        cell={props => this.customCell(props, date, 'Planned')}
                                        footerCell={() => this.setDailyTotal(resource.id, date, 'Planned')}
                                    />
                                </GridColumn>
                            ))}                   
                          </Grid>
                      </div>
                      </PanelBarItem>
                    </PanelBar>
                ))}
          </div>
        </div>

        <Loader loading={this.state.loading} />

      </div>
    );
  }
}

export default DailyUtilization;



